<template>
  <div class="subpage">

    <FrameMD
        :posY="posY"
        :transitionPhoto="false"
        :staticPhoto="true"
        :photo="pageContent.section0.photo"
        :photoHeight="'500px'"
        :header="pageContent.section0.header"
        :text="pageContent.section0.text"
    ></FrameMD>
    
    <div class="container">
      <FrameSM
        v-for="(frame, index) in pageContent.section1" :key="index"
        :posY="posY"
        :transitionPhoto="false"
        :photo="frame.photo"
        :header="frame.header"
        :text="frame.text"
        :reversed="index % 2 == 0 ? false : true"
        :style="index == 0 ? {marginTop: '40px'} : null"
      ></FrameSM>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import FrameMD from '@/components/FrameMD'
import FrameSM from '@/components/FrameSM'

export default {
  name: 'About',
  props: ['posY'],
  components: {FrameMD, FrameSM},
  data () {
      return {

      }
  },
  computed: {
    ...mapState({pageContent: state => state.data.about})
  }
}

</script>

<style lang="scss" scoped>

.container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 100px auto;
}

</style>